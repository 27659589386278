<template>
  <div class="card mb-5 mb-xl-10 p-2">
    <div class="card-header border-1 cursor-pointer">
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">{{ $t("Profile Details") }}</h3>
      </div>
    </div>
    <BankProfileDetailsForm
      ref="profileDetailsFormRef"
      :userData="user"
      :buttonIndicator="buttonIndicator"
      :countriesAndProductAreasData="userDetails.countriesAndProductAreasData"
      @handle-save="onSubmit"
    />
  </div>


  <div class="card mb-5 mb-xl-10">
    <div class="card-header border-0 cursor-pointer">
      <div class="card-title m-0">
        <h3 class="fw-boldest m-0">{{ $t("Sign - in Method") }}</h3>
      </div>

<!--      <div class="d-flex align-items-center">-->
<!--        <label class="me-3 fw-bold text-gray-600">{{ $t("Secure your account") }}</label>-->
<!--        <el-switch v-model="delivery" />-->
<!--      </div>-->
    </div>
    <SignInMethod
      :signInMethod="user"
    />
  </div>

  <div class="card mb-5 mb-xl-10">
    <div class="card-header border-0 cursor-pointer">
      <div class="card-title m-0">
        <h3 class="fw-boldest m-0">{{ $t("Delete Account") }}</h3>
      </div>
    </div>
    <DeactivateAccount
      :type="'user'"
    />
  </div>
</template>
<script>
  import DeactivateAccount from "@/buying-teams/shared-components/settings/DeactivateAccount";
  import SignInMethod from "@/buying-teams/shared-components/settings/SignInMethod";
  import store from "@/store";
  import NotificationService from "../../../../services/NotificationService";
  import BankProfileDetailsForm from "@/buying-teams/pages/bank/settings/user/BankProfileDetailsForm";
  import { DataService } from "@/core/services/DataService";
  import { BankUserSettingsContainer } from "@/store/models/bank/BankUserSettingsContainer";
  import {BlankImageTypesEnum} from "@/store/enums/BlankImageTypesEnum";

  export default {
    name: "Settings",
    components: {
      BankProfileDetailsForm,
      DeactivateAccount,
      SignInMethod
    },
    props: {
      userDetails: BankUserSettingsContainer
    },
    emits: ['onCountryFollow'],
    data() {
      return {
        delivery: "",
        profileDetails: {},
        buttonIndicator: 'off',
        BlankImageTypesEnum
      };
    },
    computed: {
      user() {
        return store.getters.currentUser;
      }
    },
    mounted() {
    },
    methods: {
      async onSubmit() {
        this.buttonIndicator = "on";
        this.profileDetails = this.$refs.profileDetailsFormRef.$refs.profileFormRef.model;
        await this.$refs.profileDetailsFormRef.$refs.profileFormRef.validate(async valid => {
          if (valid) {
            await store.dispatch("updateBankUserProfileDetails", {
                user_data: this.profileDetails
            })
              .then(res => {
                this.confirmProfileUpdate();
                NotificationService.swalCustom({
                  title: "Success",
                  icon: "success"
                });
              }).catch(err => {
                console.log(err, "----err");
              }).finally(() => {
                this.buttonIndicator = "off";
              });
          }
        });
      },
      confirmProfileUpdate() {
        this.user.selfUpdate(this.profileDetails);
        DataService.storeAuthUserData(this.user);
      }
    }

  };
</script>
<style lang="scss">

</style>
